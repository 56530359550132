// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-layouts-articles-js": () => import("./../../../src/layouts/articles.js" /* webpackChunkName: "component---src-layouts-articles-js" */),
  "component---src-layouts-blog-post-js": () => import("./../../../src/layouts/blogPost.js" /* webpackChunkName: "component---src-layouts-blog-post-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-controlls-js": () => import("./../../../src/pages/controlls.js" /* webpackChunkName: "component---src-pages-controlls-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-twitch-layout-js": () => import("./../../../src/pages/twitchLayout.js" /* webpackChunkName: "component---src-pages-twitch-layout-js" */)
}

